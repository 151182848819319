<template>
  <div id="pellet-list">
    <v-card>
      <v-card-title> テンプレート(一覧) </v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'template-create', params: { query: this.$route.query } }"
          >
            <span>NEW</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="filteredTable"
        :headers="headers"
        :items="templateList"
        :search="searchQuery"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <delete-form
              :id="item.tmpl_id"
              class="py-2 pl-1"
              :icon="icons.mdiDelete"
              @deleted="loadData"
              @cancel="loadData"
            ></delete-form>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPencil, mdiDelete, mdiExportVariant,
} from '@mdi/js'
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

import DeleteForm from './Delete.vue'

export default {
  components: {
    DeleteForm,
  },
  data: () => ({
    icons: {
      mdiPencil, mdiDelete, mdiExportVariant,
    },
    searchQuery: '',
    headers: [
      {
        text: 'ID',
        value: 'tmpl_id',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: 'テンプレート名',
        align: 'left',
        value: 'tmpl_name',
        width: '20%',
        fixed: true,
      },
      {
        text: '書類種別',
        value: 'tmpl_type',
        sortable: false,
        align: 'left',
        width: '20%',
        fixed: true,
      },
      {
        text: 'テンプレートメモ',
        value: 'tmpl_memo',
        sortable: false,
        align: 'left',
        width: '40%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'right',
        width: '10%',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('templateStore', ['templateList']),
  },
  watch: {
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('templateStore', ['loadTemplates']),

    loadData() {
      this.setOverlayStatus(true)
      this.loadTemplates().finally(() => {
        this.setOverlayStatus(false)
      })
    },

    editItem(item) {
      console.log(item)
      this.$router.push({
        name: 'template-edit',
        params: {
          id: item.tmpl_id, query: this.$route.query,
        },
      })
    },

    downloadCSV() {
      // const items = this.$refs.filteredTable.$children[0].filteredItems
      // const sorted = this.$refs.filteredTable.$children[0].sortItems(items)

      // // eslint-disable-next-line no-useless-concat
      // let csv = '\ufeff' + 'No,ペレットID,ペレット名,説明\n'
      // let no = 0
      // sorted.forEach(el => {
      //   if (el.pellet_description === null) {
      //     // eslint-disable-next-line no-param-reassign
      //     el.pellet_description = ' '
      //   }

      //   no += 1
      //   const line = `${no},"${el.pellet_id}","${el.pellet_name}","${el.pellet_description}"\n`
      //   csv += line
      // })
      // const blob = new Blob([csv], {
      //   type: 'text/csv',
      // })
      // const link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // const nowstr = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 19)
      // const newstr = nowstr.replace(/(-|:|T)/g, '')
      // link.download = 'ペレット一覧'.concat('-', newstr, '.csv')
      // link.click()
    },
  },
}
</script>
