<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icon }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >削除</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status == 'error'">
              <div
                v-for="msg of apierror.messages"
                :key="msg"
              >
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row>
              <v-col cols="4">
                <span>テンプレート名</span>
              </v-col>
              <v-col cols="8">
                {{ selectedTemplate.tmpl_name }}
              </v-col>
              <v-col cols="4">
                <span>書類種別</span>
              </v-col>
              <v-col cols="8">
                {{ selectedTemplate.tmpl_type }}
              </v-col>
              <v-col cols="4">
                <span>テンプレートメモ</span>
              </v-col>
              <v-col cols="8">
                {{ selectedTemplate.tmpl_memo }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="deleteItem(item)"
          >
            YES
          </v-btn>
          <v-btn
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

export default {
  props: ['icon', 'id'],
  data: () => ({
    submitStatus: false,
    dialog: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('templateStore', ['selectedTemplate']),
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('templateStore', ['deleteTemplate', 'loadTemplate']),

    deleteItem(item) {
      this.apierror.messages = []
      this.submitStatus = true
      this.deleteTemplate(item.id)
        .then(() => {
          this.submitStatus = false
          this.dialog = false
          this.$emit('deleted')
        })
        .catch(() => {
          this.submitStatus = false
          this.apierror.status = 'error'
          this.apierror.messages.push('使用中か削除済みです。')
          console.log('apierror', this.apierror)
        })
    },

    openForm() {
      this.setOverlayStatus(true)
      this.loadTemplate(this.id)
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.dialog = true
          this.setOverlayStatus(false)
        })
    },
    closeForm() {
      this.$emit('cancel')
      this.$emit('deleted')
      this.apierror.messages = []
      this.dialog = false
    },
  },
}
</script>
<style>
</style>
